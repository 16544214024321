import {
  ru,
  format,
  isSameDay,
  subDays,
} from 'core/libs/date-fns';

export function cardDateFormat(isoDate) {
  const date = new Date(isoDate);
  const now = Date.now();

  let pattern;

  if (isSameDay(date, now)) {
    pattern = 'Сегодня, HH:mm';
  } else if (isSameDay(date, subDays(now, 1))) {
    pattern = 'Вчера, HH:mm';
  } else {
    pattern = 'dd.MM.yyyy';
  }

  return format(date, pattern, { locale: ru });
}

/**
 * Возвращает количество полных лет между датами.
 * @param {Date} date1
 * @param {Date} date2
 */
export function getFullYearsDelta(date1, date2) {
  const yearsDelta = date2.getFullYear() - date1.getFullYear();
  const monthsDelta = date2.getMonth() - date1.getMonth();
  const daysDelta = date2.getDate() - date1.getDate();
  if (monthsDelta < 0 || (monthsDelta === 0 && daysDelta < 0)) {
    return yearsDelta - 1;
  }
  return yearsDelta;
}
