import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';

import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';

import GradientMask from 'site/components/GradientMask';

import { PVP_NAV } from 'site/constants';

import Info from './Info';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'authors',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'read_duration',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

const IMAGE_MAX_WIDTH = 750;

function CardPvp2(props) {
  const {
    content,
    theme,
  } = props;

  const {
    link,
    headline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.cardPvp2}
          max-width ${IMAGE_MAX_WIDTH}px
          color ${theme.colors.primary}
          background ${theme.colors.gray100}
      `}</style>
    </scope>
  );

  return (
    <Link
      to={link}
      type='primary'
      className={scope.wrapClassNames(styles.cardPvp2)}
      id={`${PVP_NAV}-${content.id}`}
    >
      {versions.original && (
        <div className={styles.cover}>
          <GradientMask containerType='cardPvp2'>
            <SmartImage
              versions={versions}
              maxWidth={IMAGE_MAX_WIDTH}
              aspectRatio={1.79}
            />
          </GradientMask>
        </div>
      )}
      <div className={styles.content}>
        <Texts type='title3' className={styles.headline}>
          <MarkdownWrapper inline>{headline}</MarkdownWrapper>
        </Texts>
        <Info content={content} className={styles.info} />
      </div>
      <scope.styles />
    </Link>
  );
}

CardPvp2.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  theme: PropTypes.object,
};

const Card = withTheme(CardPvp2);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { CardPvp2 as StorybookComponent };
export default Card;
