import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'core/libs/lodash';

import { Mobile } from 'core/components/breakpoint';

import { Indent } from 'core/components/Wrappers';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import GradientBackground from 'site/components/GradientBackground';

import styles from './index.styl';

function TableMarkdown({ block, parentProps }) {
  const { theme, isMobile } = parentProps;
  const { body, headline } = block.attributes;

  const rangeRef = useRef(null);
  const tableRef = useRef(null);
  const tickingRange = useRef(false);
  const [currentRange, setRange] = useState(0);

  const handleRange = event => {
    const { value } = event.target;

    if (!tickingRange.current) {
      requestAnimationFrame(() => {
        tickingRange.current = false;
        const width = tableRef.current.scrollWidth - tableRef.current.offsetWidth;
        tableRef.current.scrollLeft = width * (value / 100);
      });
    }

    tickingRange.current = true;
  };

  const handleTableScroll = throttle(() => {
    const width = tableRef.current.scrollWidth - tableRef.current.offsetWidth;
    const percent = Math.round(tableRef.current.scrollLeft / width * 100);
    if (Number(rangeRef.current.value) !== percent) {
      setRange(percent);
    }
  }, 16);

  return (
    <div className={styles.tableMarkdown}>
      <style jsx>{`
        .${styles.table}
          :global(th)
          :global(strong)
            font ${theme.texts.title4.font}
            color ${theme.texts.title4.color}

            :global(.mobile) &
              font ${theme.texts.title2.font}
              color ${theme.texts.title2.color}

          :global(td)
            font ${theme.texts.body.font}
            color ${theme.texts.body.color}

            &:first-child
              &:before
                color ${theme.colors.white}

                :global(.mobile) &
                  font ${theme.texts.bodySmall.font}
      `}</style>
      <GradientBackground className={styles.gradient}>
        <div className={styles.content}>
          {headline && (
            <Indent bottom={isMobile ? '20rem' : '40rem'}>
              <Texts
                tag='h2'
                type={isMobile ? 'accent' : 'title3'}
                className={styles.headline}
              >
                {headline}
              </Texts>
            </Indent>
          )}
          <Mobile>
            <form className={styles.rangeSlider}>
              <input
                ref={rangeRef}
                className={styles.rangeInput}
                type='range'
                readOnly{...isMobile && { onInput: handleRange }}
                value={currentRange}
              />
            </form>
          </Mobile>
          <div
            className={styles.table}
            ref={tableRef}
            {...isMobile && { onScroll: handleTableScroll }}
          >
            <MarkdownWrapper>{body}</MarkdownWrapper>
          </div>
        </div>
      </GradientBackground>
    </div>
  );
}

TableMarkdown.propTypes = {
  parentProps: PropTypes.object,
  block: PropTypes.object,
};

export default TableMarkdown;
