import { POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG } from 'site/constants.js';

export const PILES_DATA = [
  {
    slug: POKUPKA_SLUG,
    buttonText: 'Хочу купить',
  },
  {
    slug: VLADENIE_SLUG,
    buttonText: 'Есть авто',
  },
  {
    slug: PRODAGA_SLUG,
    buttonText: 'Хочу продать',
  },
];

