import cx from 'classnames';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import { replaceSpaces } from 'core/utils/content';

import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';
import { withBreakpoint, Desktop } from 'core/components/breakpoint';

import ScrollerVertical from 'site/components/ScrollerVertical';

import TrafficLight, { trafficColors } from 'site/icons/TrafficLight';

import styles from './index.styl';

/**
 * Поскольку рендер элемента списка происходит внутри Markdown, мы не можем получить position снаружи.
 * В рамках одного виджета текст, может быть несколько заголовков.
 * @type {number}
 */
let position = 0;

function LineOfContentsItem({ level, children, theme, handleModalMobile }) {
  const { colorInner, colorOuter } = trafficColors[position % trafficColors.length];
  position += 1;

  let anchor;
  if (children[0].type === 'a') {
    anchor = children[0]?.props?.children[0];
  }

  const text = children[0];

  return (
    <li className={cx(styles.item, level === 3 && styles._level3)}>
      <style jsx>{`
        .${styles.link}
          color ${theme.colors.primary}
          font ${theme.texts.bodySmall.font}

          @media (max-width: 1400px)
            font ${theme.texts.body.font}

          &:hover
            color ${theme.colors.active1}

          :global(.mobile) &
            font ${theme.texts.body.font}
      `}</style>
      <a
        title={anchor || children}
        className={styles.link}
        href={`#${replaceSpaces(text)}`}
        {...handleModalMobile && { onClick: handleModalMobile }}
      >
        <Desktop>
          <TrafficLight
            width={40}
            height={40}
            colorInner={colorInner}
            colorOuter={colorOuter}
            className={styles.icon}
          />
        </Desktop>
        <span className={styles.text}>
          {anchor || children}
        </span>
      </a>
    </li>
  );
}

LineOfContentsItem.propTypes = {
  level: PropTypes.number,
  /**
   * Обработчик модального окна.
   * Актуален только в мобильной версии.
   */
  handleModalMobile: PropTypes.func,
  theme: PropTypes.object,
};

function LineOfContents({ body, ...otherProps }) {
  const LineOfContentsItemWithProps = bindProps(otherProps)(LineOfContentsItem);

  return (
    <Markdown
      allowElement={(element, index, parent) => {
        const headerTags = ['h2'];
        const isHeader = headerTags.includes(element.tagName);
        const isHeaderChild = headerTags.includes(parent.tagName);

        return isHeader || isHeaderChild;
      }}
      components={{
        h2: LineOfContentsItemWithProps,
      }}
    >
      {body}
    </Markdown>
  );
}

LineOfContents.propTypes = {
  body: PropTypes.string.isRequired,
};

const LineOfContentsWithHOCs = withBreakpoint(withTheme(LineOfContents));

export { LineOfContentsWithHOCs as LineOfContents };

export default function ListOfContents({ children }) {
  return (
    <ScrollerVertical className={styles.listOfContents} showTrack>
      <ul className={styles.list}>
        {children}
      </ul>
    </ScrollerVertical>
  );
}

