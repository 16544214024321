import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';
import themeSwitcher from 'core/components/themeSwitcher';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

function CardRect1(props) {
  const {
    content,
    theme,
    imageMaxWidth,
    aspectRatio,
    hideAltHeadline,
    cardHeight,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: alternativeHeadline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  const {
    colors: {
      green350,
      gray100,
      blue700,
      white,
      black,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.cardRect1}
          max-width ${imageMaxWidth}px
          background-color ${gray100}
          height ${cardHeight}rem

        .${styles.cardRect1}
          &:hover
            .${styles.body}
              background-image radial-gradient(102% 560% at 0% 92%, ${green350} -40%, ${blue700} 60%)

        .${styles.body}
          transform translateY(calc(${(100 / aspectRatio).toFixed(2) + '%'} - 44rem))

        .${styles.headline}
        .${styles.altHeadline}
          color ${black}
          .${styles.cardRect1}:hover &
            color ${white}
      `}</style>
    </scope>
  );

  const title = listHeadline || headline;

  return (
    <Link
      to={link}
      title={title}
      type='primary'
      className={scope.wrapClassNames(
        styles.cardRect1,
        hideAltHeadline && styles._hideAltHeadline,
      )}
    >
      {versions.original && (
        <div className={styles.image}>
          <GradientMask hideEllipsis>
            <SmartImage
              versions={versions}
              maxWidth={imageMaxWidth}
              aspectRatio={aspectRatio}
            />
          </GradientMask>
        </div>
      )}
      <div className={scope.wrapClassNames(styles.body)}>
        <div>
          <Texts type='title4' className={scope.wrapClassNames(styles.headline)}>
            <MarkdownWrapper inline>{title}</MarkdownWrapper>
          </Texts>
        </div>
        <div className={styles.altHeadlineWrapper}>
          <Texts type='body' className={scope.wrapClassNames(styles.altHeadline)}>
            <MarkdownWrapper inline>{alternativeHeadline}</MarkdownWrapper>
          </Texts>
        </div>
      </div>
      <scope.styles />
    </Link>
  );
}

CardRect1.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  theme: PropTypes.object,
  imageMaxWidth: PropTypes.number,
  aspectRatio: PropTypes.number,
  hideAltHeadline: PropTypes.bool,
  cardHeight: PropTypes.number,
};

CardRect1.defaultProps = {
  imageMaxWidth: 514,
  aspectRatio: 1.38,
  cardHeight: 440,
};

const Card = themeSwitcher('dark')(withTheme(CardRect1));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { CardRect1 as StorybookComponent };
export default Card;
