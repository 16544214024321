import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import Texts from 'core/components/Texts';
import Button from 'site/components/Button';

import TravelMeta from 'site/components/TravelMeta';
import Reactions from 'site/components/Reactions';

import MainSwipe from 'site/components/MainSwipe';

import styles from './index.styl';


const relationships = resolveRelationships(['travel_meta'], null, {});

export default function MobileTravels(props)  {
  const {
    topics,
    activeIndex,
    onActiveChange,
  } = props;

  const content = topics[activeIndex];

  const {
    headline,
    list_headline: listHeadline,
    link,
  } = content?.attributes || {};

  const {
    travel_meta: travelMeta,
  } = relationships(content);

  const blockHeadline = listHeadline || headline;

  return (
    <div className={styles.content}>
      <Texts className={styles.headline} type='title3'>{blockHeadline}</Texts>
      <TravelMeta travelMeta={travelMeta} />
      <Reactions key={content.id} targetId={content.id} />
      <MainSwipe
        content={topics}
        altHeadlineSlug='alternative_headline'
        activeIndex={activeIndex}
        onActiveIndexChange={onActiveChange}
        gradientClassName={styles.gradientMask}
        isTravels
      />
      <Button
        link={link}
        minWidth='100%'
        size='large'
      >
        Читать обзор
      </Button>
    </div>
  );
}

MobileTravels.propTypes = {
  topics: PropTypes.array,
  activeIndex: PropTypes.number,
  onActiveChange: PropTypes.func,
};
