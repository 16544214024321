import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import { filterRequiredParams, denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import CardHorizontal from 'site/cards/CardHorizontal';
import CardVertical from 'site/cards/CardVertical';
import CardReview from 'site/components/Reviews/Desktop';
import TestDrives from './TestDrives';
import Travels from './Travels';
import { ANSWER_LIMIT } from './UrgentAnswers';

export default function useMainContent(history) {
  const results = useRequest({
    queries: [
      topicsQuery({
        history,
        autocompleted_list_limit: 4,
        list: 'statya-na-glavnoy',
        sort: 'list',
        topic_type: 'article',
        visibility: 'main_page',
        include: filterRequiredParams([CardHorizontal, CardVertical], 'include'),
        fields: filterRequiredParams([CardHorizontal, CardVertical], 'fields'),
        renderError: () => [],
      }),
      topicsQuery({
        history,
        topic_type: 'article',
        visibility: 'main_page',
        with_filtered_count: 1,
        fields: '',
        renderError: () => [],
      }, {
        select: data => data?.meta.filtered_count,
      }),

      topicsQuery({
        history,
        list: 'obzory-na-glavnoy',
        sort: 'list',
        include: filterRequiredParams([CardReview], 'include'),
        fields: filterRequiredParams([CardReview], 'fields'),
        renderError: () => [],
      }),
      topicsQuery({
        history,
        topic_type: 'car_review',
        visibility: 'main_page',
        with_filtered_count: 1,
        fields: '',
        limit: 1,
        renderError: () => [],
      }, {
        select: data => data?.meta.filtered_count,
      }),

      topicsQuery({
        history,
        list: 'gaydy-na-glavnoy',
        sort: 'list',
        include: 'image',
        fields: 'link,headline,list_headline,announce',
        renderError: () => [],
      }),
      topicsQuery({
        history,
        rubric: 'guides',
        visibility: 'main_page',
        with_filtered_count: 1,
        fields: '',
        limit: 1,
        renderError: () => [],
      }, {
        select: data => data?.meta.filtered_count,
      }),

      topicsQuery({
        history,
        limit: 16,
        topic_type: 'test_drive',
        visibility: 'main_page',
        include: filterRequiredParams([TestDrives], 'include'),
        fields: filterRequiredParams([TestDrives], 'fields'),
        with_filtered_count: 1,
        renderError: () => [],
      }, {
        select: data => {
          return {
            topics: denormalizeData(data),
            count: data?.meta.filtered_count,
          };
        },
      }),
      topicsQuery({
        history,
        limit: 5,
        topic_type: 'travel',
        visibility: 'main_page',
        include: filterRequiredParams([Travels], 'include'),
        fields: filterRequiredParams([Travels], 'fields'),
        with_filtered_count: 1,
        renderError: () => [],
      }, {
        select: data => {
          return {
            topics: denormalizeData(data),
            count: data?.meta.filtered_count,
          };
        },
      }),
      topicsQuery({
        history,
        list: 'srochnye-otvety',
        sort: 'list',
        visibility: 'main_page',
        include: 'image',
        fields: 'link,list_headline,headline',
        limit: ANSWER_LIMIT,
        renderError: () => [],
      }),
    ],
  });

  const {
    data: [
      articles,
      articlesCount,
      reviews,
      reviewsCount,
      guides,
      guidesCount,
      testDrives,
      travels,
      urgentAnswers,
    ],
    isLoading,
  } = processQueryResults(results);


  if (isLoading) {
    return { isLoading: true };
  }

  return {
    articles: {
      topics: articles,
      count: articlesCount,
    },
    reviews: {
      topics: reviews,
      count: reviewsCount,
    },
    guides: {
      topics: guides,
      count: guidesCount,
    },
    testDrives,
    travels,
    urgentAnswers,
  };
}
