import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import themeSwitcher from 'core/components/themeSwitcher';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import ReadDuration from 'site/components/ReadDuration';
import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'authors',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'read_duration',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
  authors: [],
});

function CardFullwidth({ content }) {
  const {
    link,
    headline,
    list_headline: listHeadline,
    read_duration: readDuration,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
    authors,
  } = relationships(content) || {};

  const authorNames = authors.map(author => author.attributes.name).join(', ');
  const authorNamesPrefix = (authors.length > 1 ? 'Авторы' : 'Автор');
  const title = listHeadline || headline;

  return (
    <Link
      to={link}
      title={title}
      type='primary'
      className={styles.cardFullwidth}
    >
      {versions.original && (
        <div className={styles.cover}>
          <GradientMask hideEllipsis>
            <SmartImage
              versions={versions}
              aspectRatio={2.32}
            />
          </GradientMask>
        </div>
      )}
      <div className={styles.body}>
        <Texts type='title3' className={styles.title}>
          <MarkdownWrapper inline>{title}</MarkdownWrapper>
        </Texts>
        <Texts type='bodySmall' className={styles.info}>
          {authorNames && (
            <div className={styles.authors}>
              {authorNamesPrefix + ': ' + authorNames}
            </div>
          )}
          {readDuration && <ReadDuration readDuration={readDuration} />}
        </Texts>
      </div>
    </Link>
  );
}

CardFullwidth.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  theme: PropTypes.object,
};

const Card = themeSwitcher('dark')(withTheme(CardFullwidth));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { CardFullwidth as StorybookComponent };
export default Card;
