import deepmerge from 'core/libs/deepmerge';

import Logo from 'site/icons/Logo';
import ArrowLeft from 'site/icons/ArrowLeft';
import ArrowRight from 'site/icons/ArrowRight';
import Burger from 'site/icons/Burger';
import Close from 'site/icons/Close';
import Search from 'site/icons/Search';
import GradientArrowLeft from 'site/icons/GradientArrowLeft.svg';
import GradientArrowRight from 'site/icons/GradientArrowRight.svg';
import GradientClose from 'site/icons/GradientClose.svg';
import { GalleryArrowRight, GalleryArrowLeft } from 'site/widgets/PhotoGallery/Arrows';

import fonts from './fonts';

import { SIDE_INDENT, LAYOUT_MAX_WIDTH } from 'site/constants';


const icons = {
  logo: Logo,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  burger: Burger,
  close: Close,
  search: Search,
};

export default function baseTheme(atoms) {
  const animations = {
    hover: '.15s linear',
    scale: '.3s ease-out',
  };

  const skeleton = {
    background: atoms.colors.gray300,
  };

  const layout = {
    maxWidth: LAYOUT_MAX_WIDTH + 'rem',
    minWidth: '1280px',
    boxShadow: 'none',
    indents: {
      top: 64,
      right: SIDE_INDENT,
      bottom: 120,
      left: SIDE_INDENT,
    },
  };

  const texts = {
    get headline() {
      return this.title1;
    },

    title1: {
      font: `700 104rem/.9 ${fonts.display}`,
      letterSpacing: '-0.02em',
    },

    title2: {
      font: `600 48rem/46rem ${fonts.display}`,
      letterSpacing: '0',
    },

    title3: {
      font: `700 80rem/1 ${fonts.display}`,
      letterSpacing: '0.01em',
    },

    title4: {
      font: `600 32rem/32rem ${fonts.display}`,
      letterSpacing: '0',
    },

    title4Medium: {
      font: `600 40rem/44rem ${fonts.display}`,
    },

    title5: {
      font: `600 32rem/30rem ${fonts.display}`,
    },

    button: {
      font: `400 24rem/34rem ${fonts.display}`,
      letterSpacing: '0.01em',
    },

    buttonCTA: {
      font: `600 24rem/24rem ${fonts.display}`,
      letterSpacing: '0',
    },

    accent: {
      font: `700 56rem/62rem ${fonts.display}`,
      letterSpacing: '0.01em',
    },

    quote: {
      font: `700 32rem/38rem ${fonts.display}`,
      letterSpacing: '-0.01em',
    },

    body: {
      font: `400 24rem/34rem ${fonts.text}`,
      letterSpacing: '0.01em',
    },

    body2: {
      font: `400 24rem/1 ${fonts.text}`,
      letterSpacing: '0.01em',
    },

    bodySmall: {
      font: `400 20rem/24rem ${fonts.display}`,
      letterSpacing: '0.01em',
    },
  };

  const sizes = {
    extraSmall: {
      padding: 0,
      height: '64rem',
      width: '64rem',
    },
    small: {
      padding: '11rem 24rem',
      font: texts.body.font,
      height: '0',
      width: '0',
    },
    medium: {
      height: '56rem',
      font: texts.button.font,
      padding: '16rem 24rem',
      width: '72rem',
    },
    large: {
      height: '84rem',
      font: texts.buttonCTA.font,
      padding: '20rem 50rem',
      width: '72rem',
    },
    extraLarge: {
      height: '198rem',
      font: texts.button.font,
      width: '198rem',
    },
  };

  const link = {
    section: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'none',
      },

      hover: {
        color: atoms.colors.blue500,
        decoration: 'none',
      },

      active: {
        color: atoms.colors.blue900,
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
    },
  };

  const button = {
    const: {
      sizes,
      radius: '100rem',
    },

    primary: {
      idle: {
        color: atoms.colors.white,
        background: atoms.colors.blue500,
        border: 'none',
      },

      hover: {
        color: atoms.colors.black,
        background: atoms.colors.blue500,
        border: 'none',
      },

      active: {
        color: atoms.colors.black,
        background: atoms.colors.blue500,
        border: 'none',
      },

      disabled: {
        color: atoms.colors.gray800,
        background: atoms.colors.gray500,
        border: 'none',
      },
    },

    tertiary: {
      idle: {
        color: atoms.colors.black,
        background: atoms.colors.white,
        border: 'none',
      },

      hover: {
        color: atoms.colors.black,
        background: atoms.colors.blue500,
        border: 'none',
      },

      active: {
        color: atoms.colors.black,
        background: atoms.colors.blue500,
        border: 'none',
      },

      disabled: {
        color: atoms.colors.gray800,
        background: atoms.colors.gray500,
        border: 'none',
      },
    },
  };

  const input = {
    const: {
      sizes,
      radius: '100rem',
    },
  };

  const searchInput = {
    icons: {
      Search: icons.search,
      Close: Close,
    },

    iconStyles: {
      withText: {
        focused: {
          color: atoms.colors.active1,
        },
      },
    },
  };

  const scooter = {
    logo: {
      width: 116,
      height: 70,
    },
  };

  const topicContent = {
    widgetsMaxWidth: LAYOUT_MAX_WIDTH,
    widgetsWithMaxWidth: {
      'markdown': true,
      'image': true,
      'photoGallery': true,
    },
    widget: {
      top: -64,
      bottom: -64,
    },
    image: {
      bottom: 0,
    },
  };

  const drum = {
    arrow: {
      Left: GradientArrowLeft,
      Right: GradientArrowRight,

      width: '54',
      height: '54',

      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },

      /**
       * В GradientArrows собственные цвета
       */
      idle: {
        color: 'transparent',
      },

      hover: {
        color: 'transparent',
      },
    },
  };

  const vikontReadMore = {
    block: {
      padding: 0,
    },
    feed: {
      columns: 2,
    },
    drum: {
      arrowsShift: -20,
      arrowsShiftVertical: 0,
      itemWidth: '25%',
      spaceBetween: 16,
      sideIndent: 0,
    },
  };

  const reactions = {
    small: {
      font: texts.button.font,
      radius: '32rem',
      padding: '11rem 20rem',
    },

    primary: {
      idle: {
        color: atoms.colors.black,
        border: '2px solid transparent',
        background: atoms.colors.white,
      },
      checked: {
        color: atoms.colors.white,
        border: '2px solid transparent',
        background: atoms.colors.blue700,
      },
      focus: {
        color: atoms.colors.blue700,
        border: `2px solid ${atoms.colors.blue700}`,
        background: atoms.colors.white,
      },
      disabled: {
        color: atoms.colors.black,
        border: '2px solid transparent',
        background: atoms.colors.white,
      },
      checkedDisabled: {
        color: atoms.colors.white,
        border: '2px solid transparent',
        background: atoms.colors.blue700,
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.black,
        border: '1px solid transparent',
        background: atoms.colors.gray100,
      },
      checked: {
        color: atoms.colors.white,
        border: '1px solid transparent',
        background: atoms.colors.blue700,
      },
      focus: {
        color: atoms.colors.blue700,
        border: `1px solid ${atoms.colors.blue700}`,
        background: atoms.colors.gray100,
      },
      disabled: {
        color: atoms.colors.black,
        border: '1px solid transparent',
        background: atoms.colors.gray100,
      },
      checkedDisabled: {
        color: atoms.colors.white,
        border: '1px solid transparent',
        background: atoms.colors.blue700,
      },
    },
  };

  /**
   * paginatron на странице поиска
   */
  const paginatron = {
    btnType: 'primary',
    withoutBorder: true,
    withOuterButtons: false,
    round: false,
    separationText: ' / ',
    PrevPage: () => 'Назад',
    NextPage: () => 'Вперед',
    justifyContent: 'space-between',
  };

  const gallery = {
    icons: {
      Close: GradientClose,
    },

    thumb: {
      borderRadius: 8,
      active: {
        borderColor: 'transparent',
      },
    },

    arrows: {
      Left: GalleryArrowLeft,
      Right: GalleryArrowRight,
    },
  };

  const captionCredits = {
    caption: {
      font: `600 32rem/1 ${fonts.display}`,
    },
    credits: {
      font: `400 20rem/1 ${fonts.display}`,
      margin: '8rem 0 0',
    },
  };

  const cookiesBanner = {
    background: atoms.colors.gray100,
    text: {
      font: `400 12rem/16rem ${fonts.text}`,
      color: atoms.colors.primary,
    },
    button: {
      btnSize: 'extraSmall',
      btnRadius: '18rem',
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    layout,
    animations,
    controls: {
      link,
      button,
      input,
      searchInput,
      scooter,
      topicContent,
      vikontReadMore,
      skeleton,
      reactions,
      paginatron,
      gallery,
      drum,
      captionCredits,
      cookiesBanner,
    },
  }, atoms);
}
